import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "Domain Management & Registration",
  sectionHeader:
    "Making domain management and registration easy with goSystem.",
  sectionContent:
    "goSystem makes domain management easy with our intuitive cloud portal. We can even take care of your domains for you, making it easier than ever to manage your online presence. Our affordable pricing makes goSystem the ideal choice for startups and large enterprises alike.",
  splitTag: "Self-managed to fully managed",
  splitHeading: "Making your domains our priority.",
  splitContent:
    "Our team are dedicated to your needs. Wether you have a single domain and are looking to move to goSystem, or have multiple domains and looking for a better way to manage them, we have a solution for you.\n\nOur domain brokerage services gives ypu the chance to get the domain you want, for the price you want. We do the work, you get the domain!",
  callToAction: "Domains? No worries! With goSystem",
};

export default () => ServicesTemplate(config);
